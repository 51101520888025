        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="4"
                                        >
                            <select-input
  id="cp"
  name="cp"
    :value.sync="xparam1"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
        :searchable="false"
            label="Период"
  api="https://api.vestaorganic.ru/api/v1/json/lk/service/data/list/structCP"
  />
            </b-col>
                                        <b-col
                                                                                      sm="6"
                                        >
                            <search-input
    :value.sync="qqq"
    api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/search/list/struct"
    />
            </b-col>
                                        <b-col
                                                                                      sm="2"
                                        >
                            <select-input
  id="filtr"
  name="filtr"
    :value.sync="xparam2"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
        :searchable="false"
            label="Фильтр"
  api="https://api.vestaorganic.ru/api/v1/json/lk/service/data/list/structFiltr"
  />
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <tree-grid
  :columns="[{&quot;key&quot;:&quot;npp&quot;,&quot;label&quot;:&quot;&quot;,&quot;width&quot;:&quot;45px&quot;,&quot;thClass&quot;:&quot;text-left p-0&quot;,&quot;tdClass&quot;:&quot;text-left pr-0&quot;},{&quot;key&quot;:&quot;FIO&quot;,&quot;label&quot;:&quot;\u041a\u043e\u043d\u0442\u0440\u0430\u043a\u0442&quot;,&quot;width&quot;:&quot;300px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;pl-0&quot;},{&quot;key&quot;:&quot;LO&quot;,&quot;label&quot;:&quot;\u041b\u043e&quot;,&quot;width&quot;:&quot;120px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;GO&quot;,&quot;label&quot;:&quot;\u0413\u043e&quot;,&quot;width&quot;:&quot;120px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;LG&quot;,&quot;label&quot;:&quot;\u041b\u041e \u043f\u0430\u043a&quot;,&quot;width&quot;:&quot;120px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;NGO&quot;,&quot;label&quot;:&quot;\u0413\u041e \u043f\u0430\u043a&quot;,&quot;width&quot;:&quot;120px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;Itog_Sum&quot;,&quot;label&quot;:&quot;\u0411\u043e\u043d\u0443\u0441, \u0440\u0443\u0431&quot;,&quot;width&quot;:&quot;120px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;Status&quot;,&quot;label&quot;:&quot;\u0410\u043a\u0442\u0438\u0432\u043d\u043e\u0441\u0442\u044c&quot;,&quot;type&quot;:&quot;text&quot;,&quot;width&quot;:&quot;200px&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;town_name&quot;,&quot;label&quot;:&quot;\u0413\u043e\u0440\u043e\u0434&quot;,&quot;width&quot;:&quot;200px&quot;,&quot;thClass&quot;:&quot;text-center&quot;}]"
    :xparam1="xparam1"
      :xparam2="xparam2"
      :xparam3="xparam3"
        :apiId="qqq"
          api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/three/page"
  :open="true"
  :tree="true"
  :hideAvatar="true"
/>
            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                      import SelectInput from '@core/components/input/select-input/SelectInput.vue'
                                                              import SearchInput from '@core/components/input/search-input/SearchInput.vue'
                                                                                    import TreeGrid from '@core/components/tree-grid/TreeGrid.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          SelectInput,
                                                                              SearchInput,
                                                                                                              TreeGrid,
                                          },
  setup() {
                const xparam1 = ref('0')
            const xparam2 = ref('100')
            const qqq = ref('')
            const xparam3 = ref('0')
          
          return {
            xparam1,
            xparam2,
            qqq,
            xparam3,
            }
      },
}
</script>
    