<template>
  <div>
    <div class="responsive relative">
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <tree-grid-items
        :key="componentKey"
        ref="refTreeGrid"
        :items="gridData || []"
        :fields="columns"
        :open="open"
        :tree="tree"
        :hideAvatar="hideAvatar"
        class="tree-table"
        @toggle="toggle"
      />
    </div>
    <b-row class="mt-2">

      <!-- <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
      </b-col> -->
      <!-- Pagination -->
      <!-- <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col> -->

    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  // BCol,
  // BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import TreeGridItems from './TreeGridItems.vue'
import treeGridStoreModule from './treeGridStoreModule'
import store from '@/store'
import useTreeGrid from './useTreeGrid'

export default {
  components: {
    TreeGridItems,
    BRow,
    // BCol,
    // BPagination,
    BOverlay,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
    },
    tree: {
      type: Boolean,
      required: false,
    },
    hideAvatar: {
      type: Boolean,
      required: false,
      default: false,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
    },
    xparam1: {
      type: [Object, String, null],
      required: false,
    },
    xparam2: {
      type: [Object, String, null],
      required: false,
    },
    xparam3: {
      type: [Object, String, null],
      required: false,
    },
    qqq: {
      type: [Object, String, null],
      required: false,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'tree-grid'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, treeGridStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      refTreeGrid,
      gridData,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,

      componentKey,
      toggle,

      fetchData,
    } = useTreeGrid(props)

    fetchData()

    return {
      refTreeGrid,
      gridData,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,

      componentKey,
      toggle,
      fetchData,
    }
  },
}
</script>
<style scoped>
  .responsive {
    width: 100%;
    overflow: auto;
  }
  .tree-table {
    min-width: 1300px;
  }
</style>
