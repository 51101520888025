<template>
  <b-table-simple
    :key="componentKey"
    class="tree-grid"
  >
    <b-thead>
      <b-tr>
        <b-th
          v-for="(field, key) in fields"
          :key="key"
          :style="field.width && `width: ${field.width}`"
          :class="field.thClass"
        >
          {{ field.label }}
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody v-if="itemsData.length">
      <tree-grid-item
        v-for="(item, key) in itemsData"
        :key="key"
        :item="item"
        :fields="fields"
        :open="open"
        :tree="tree"
        :hideAvatar="hideAvatar"
        @toggle="toggle"
      />
    </b-tbody>
    <b-tbody v-else>
      <tr>
        <td
          :colspan="fields.length"
          class="text-center"
        >
          Нет данных
        </td>
      </tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh,
} from 'bootstrap-vue'
import TreeGridItem from './TreeGridItem.vue'

export default {
  name: 'TreeGridItems',
  components: {
    TreeGridItem,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
  },
  props: {
    items: {
      type: [Array, Function],
      required: false,
      default: () => [],
    },
    fields: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
    },
    tree: {
      type: Boolean,
      required: false,
    },
    hideAvatar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      itemsData: [],
      componentKey: 1,
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    items(val) {
      this.forceRerender()
      this.itemsData = val
    },
  },
  async mounted() {
    this.applyItems()
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    async refresh() {
      this.applyItems()
    },
    async applyItems() {
      if (typeof this.items === 'function') {
        this.itemsData = await this.items()
      } else {
        this.itemsData = this.items
      }
    },
    toggle(item) {
      this.$emit('toggle', item)
    },
  },
}
</script>

<style scoped>
  .tree-grid {
    table-layout: fixed;
    width: 100%;
  }
</style>
