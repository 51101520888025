<template>
  <Fragment>
    <b-tr
      class="tree-grid__row"
      :class="{ 'pointer': isFolder }"
      @click="toggle(item)"
    >
      <b-td
        v-for="(field, key) in fields"
        :key="key"
        class="tree-grid__node"
        :class="`${!isFolder ? 'tree-grid__node--child' : ''} ${field['tdClass']}`"
        :style="field.width && `width: ${field.width};`"
      >
        <div
          class="align-items-center"
          :class="{'d-flex': key === 1}"
        >
          <div class="btn-wrap" :class="{ isOpen: isOpen }">
            <BButton
              v-if="isFolder && key === 1"
              variant="flat-primary"
              class="icon-wrap btn-icon"
              size="sm"
              :style="`margin-left: ${item.npp * 20}px`"
            >
              <feather-icon
                v-if="isFolder && isOpen"
                icon="MinusSquareIcon"
                size="15"
              />
              <feather-icon
                v-if="isFolder && !isOpen"
                icon="PlusSquareIcon"
                size="15"
              />
            </BButton>
          </div>
          <span
            v-if="!isFolder && key === 1 && tree"
            class="icon-wrap"
            :style="`margin-left: ${item.npp * 20}px`"
          />
          <b-media
            v-if="key === 1 && item.avatar"
            vertical-align="center"
          >
            <template #aside>
              <b-avatar
                v-if="false"
                size="32"
                :src="item.avatar.src && `${server + item.avatar.src}`"
                :text="avatarText(item[field.key].replace(/[0-9]/g, ''))"
                :variant="item.avatar.color"
              />
            </template>
            <div
              :class="field.tdClass"
              v-html="item[field.key]"
            />
          </b-media>
          <div
            v-else
            :class="field.tdClass"
            v-html="item[field.key]"
          />
        </div>
      </b-td>
    </b-tr>
    <b-tr ref="dbbutton">
      <b-td
        v-show="isOpen"
        v-if="isFolder"
        :colspan="fields.length"
        :class="`tree-grid__children`"
      >
        <b-table-simple
          class="tree-grid"
          :class="{ opened: isOpen }"
        >
          <b-thead style="display:none">
            <b-tr>
              <b-th
                v-for="(field, key) in fields"
                :key="key"
                :style="field.width && `width: ${field.width}`"
                :class="field.thClass"
              >
                {{ field.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <tree-grid-item
              v-for="(child, index) in items"
              :key="index"
              :item="child"
              :fields="fields"
              :open="open"
              :tree="tree"
              @toggle="emitToggle"
            />
            <!-- <tr>
              <td />
              <td>
                <b-dropdown
                  v-if="items.length < item.children.length"
                  :style="`margin-left: ${item.npp * 20 + 31}px`"
                  right
                  split
                  size="sm"
                  variant="primary"
                  text="Еще..."
                  @click="more(0, $event)"
                >
                  <b-dropdown-item
                    :active="perPage === 10"
                    @click="more(10, $event)"
                  >10</b-dropdown-item>
                  <b-dropdown-item
                    :active="perPage === 25"
                    @click="more(25, $event)"
                  >25</b-dropdown-item>
                  <b-dropdown-item
                    :active="perPage === 50"
                    @click="more(50, $event)"
                  >50</b-dropdown-item>
                  <b-dropdown-item
                    :active="perPage === 100"
                    @click="more(100, $event)"
                  >100</b-dropdown-item>
                </b-dropdown>
                <span
                  class="text-muted"
                  :style="items.length >= item.children.length ? `margin-left: ${item.npp * 20 + 31}px` : 'margin-left: 8px'"
                >Уровень {{ item.npp + 1 }}:</span>
              </td>
              <td
                v-for="(field, key) in fields.slice(0, fields.length - 4)"
                :key="key"
              />
              <td colspan="2">
                <span class="text-muted">{{ items.length }} {{ $t('of') }} {{ item.children.length }}</span>
              </td>
            </tr> -->
          </b-tbody>
        </b-table-simple>
      </b-td>
    </b-tr>
  </Fragment>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BButton,
  BAvatar,
  BMedia,
//   BDropdown,
//   BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { Fragment } from 'vue-fragment'

export default {
  name: 'TreeGridItem',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    Fragment,
    BButton,
    BAvatar,
    BMedia,
    // BDropdown,
    // BDropdownItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
    },
    tree: {
      type: Boolean,
      required: false,
    },
    hideAvatar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      items: [],
      page: 1,
      perPage: 50,
    }
  },
  computed: {
    isFolder() {
      return (this.item.children && this.item.children.length) || (!this.item.leaf && !this.item.children)
    },
  },
  created() {
    if (this.item.children && this.item.children.length) {
      this.items = this.item.children.slice(0, this.perPage)
      this.isOpen = this.open || false
    }
  },
  methods: {
    toggle(item) {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      }
      this.emitToggle(item)
    },
    emitToggle(item) {
      this.$emit('toggle', item)
    },
    more(count) {
      this.page += 1
      if (count) {
        this.perPage = count
      } else {
        this.items = this.item.children.slice(0, this.perPage + this.items.length)
      }
    },
  },
  setup() {
    const server = process.env.VUE_APP_SERVER
    return {
      server,
      avatarText,
    }
  },
}
</script>
<style scoped>
  .icon-wrap {
    width: 31px;
    display: inline-block;
    margin-right: 8px;
  }
  .tree-grid__children {
    padding: 0;
    border: none;
  }
  .tree-grid {
    table-layout: fixed;
    width: 100%;
  }
  .rotate {
    transform: rotate3d(0,0,1,90deg);
  }
  .pointer {
    cursor: pointer;
  }
  .tree-grid.opened {
    margin: 0;
  }
  .tree-grid.opened .tree-grid__node {
    padding-left: 28px;
    word-wrap: break-word;
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
