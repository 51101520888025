import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useTreeGrid(props) {
  const refTreeGrid = ref(null)
  const gridData = ref(null)
  const loading = ref(false)
  const total = ref(0)
  const currentPage = ref(1)
  const perPage = ref(25)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const componentKey = ref(0)

  const forceRerender = () => {
    componentKey.value += 1
  }

  const fetchTreeNode = item => {
    loading.value = true
    store.dispatch('tree-grid/fetchData', {
      api: `${props.api}/node`,
      apiId: item.id,
      params: {
        xparam3: item.npp,
      },
    }).then(response => {
      const { data } = response.data
      // eslint-disable-next-line no-param-reassign
      item.children = data
      // eslint-disable-next-line no-param-reassign
      item.leaf = true
      loading.value = false
      forceRerender()
    })
  }

  const toggle = item => {
    if (!item.leaf && (!item.children || !item.children.length)) {
      fetchTreeNode(item)
    }
  }

  const dataMeta = computed(() => {
    const localItemsCount = gridData.value ? gridData.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const fetchData = () => {
    loading.value = true
    // eslint-disable-next-line prefer-destructuring
    const xparam1 = props.xparam1
    // eslint-disable-next-line prefer-destructuring
    const xparam2 = props.xparam2
    // eslint-disable-next-line
    const xparam3 = props.xparam3
    store.dispatch('tree-grid/fetchData', {
      api: props.api,
      apiId: (props.apiId && props.apiId.id) || props.apiId,
      params: {
        xparam1,
        xparam2,
        // xparam3,
      },
    })
      .then(response => {
        const { data } = response.data
        gridData.value = data
        total.value = data.paging ? data.paging[0].total : 0
        loading.value = false
      })
      .catch(() => {
        gridData.value = null
        total.value = 0
        loading.value = false
      })
  }

  watch(() => props.apiId, () => {
    fetchData()
  })

  watch(() => props.xparam1, () => {
    fetchData()
  })

  watch(() => props.xparam2, () => {
    fetchData()
  })

  watch(() => props.qqq, () => {
    fetchData()
  })

  watch([currentPage], () => {
    fetchData()
  })

  return {
    refTreeGrid,
    gridData,
    loading,
    total,
    currentPage,
    perPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,

    componentKey,
    forceRerender,
    fetchTreeNode,
    toggle,

    fetchData,
  }
}
